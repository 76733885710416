/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Voucher, Column, ColumnWrap, ColumnWrapper, Subtitle, Text, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Voucher className="pt--40" name={"ifqzypc6cyi"} style={{"paddingLeft":57,"marginBottom":4,"paddingBottom":0}}>
        </Voucher>


        <Column className="pb--50 pt--50" anim={null} name={"informace"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" anim={""} animS={"3"} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Subtitle className="subtitle-box fs--36" style={{"maxWidth":1000}} content={"<span style=\"color: rgb(6, 178, 76);\">Antinikotinová terapie</span><br>"}>
              </Subtitle>

              <Text className="text-box fs--36" content={"<span style=\"font-weight: 700; color: rgb(7, 9, 147);\">BIOREZONANCE</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box fs--26" content={"<span style=\"color: var(--color-custom-1);\">Kontakt</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--black); font-weight: bold;\">+420 602335500<br>hranickyjiri@gmail.com<br>Dlouhá třída 1520/6a <br>Havířov Město 73601<br>Česká Republika</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":400}}>
              
              <Title className="title-box fs--24" content={"<span style=\"color: rgb(25, 53, 125);\"><a href=\"www.prestankourit.cz\" target=\"_blank\">www.prestankourit.cz</a></span><br>"}>
              </Title>

              <Text className="text-box fs--26" content={"<span style=\"color: rgba(230, 19, 58, 0.93); font-weight: bold;\">Nutné se objednat předem</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--40" name={"uvod"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--stretch" style={{"backgroundColor":"var(--white)"}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":846}}>
              
              <Title className="title-box fs--102" content={"<span style=\"color: rgb(215, 37, 14);\">Dárkový poukaz</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center fs--86 w--700" content={"<span style=\"color: rgb(26, 11, 216);\">Odvykání kouření</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--20 pt--20" name={"paticka"} style={{"backgroundColor":"var(--color-custom-1)"}}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left fs--20" content={"<span style=\"color: var(--white);\">Dárkové poukazy</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--right fs--20" content={"<span style=\"color: var(--white);\">Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}